export const DefaultConfigs = {
    contactNumber: "5585982140654",
    contactNumber2: "5585988262675",
    contactNumberMask: "(85) 98214-0654",
    contactNumberMask2: "(85) 98826-2675",
    contactEmail: "coordenacaosdgetao@outlook.com",
    address: "R. Eliane Monteiro Barros, 39 - Icaraí, Caucaia - CE, 61624-540",
    openingHours: "Segunda a Sexta das 08:00 às 18:00 horas",
    redirectTrackingLink: "http://www16.itrack.com.br/sdgestao",
    redirectTrackingLinkOld:
        "https://sdsat.trackingcenter.com.br/scripts/login.php",
    messageDefaultEmail: {
        subject: "?subject=Contato",
        body: "&body=Olá, gostaria de algumas informações a reseito de seus serviços.",
    },
    messageDefaultWhatsapp: "?text=".replace(" ", "%20"),
    dataSergio: {
        whatsappNumber: "5585988262675",
        email: "sergiojosedias@hotmail.com",
    },
    dataVictor: {
        whatsappNumber: "5585982140654",
        email: "coordenacaosdgetao@outlook.com",
    },
    dataBruno: {
        whatsappNumber: "5585981059216",
        email: "coordenacaosdgetao@outlook.com",
    },
};
