import { signInWithEmailAndPassword } from "firebase/auth";
import { useCallback, useState } from "react";

import { auth } from "../../../services/Firebase";

import Toast from "../../../components/Toast";

export default () => {
    const [loadingSignIn, setLoadingSiginIn] = useState(false);

    const signInUser = useCallback(
        async (
            email,
            password,
            successAction = () => {},
            watingFindUser = false
        ) => {
            setLoadingSiginIn(true);

            try {
                const user = await signInWithEmailAndPassword(
                    auth,
                    email,
                    password
                );

                if (user) {
                    if (watingFindUser) {
                        successAction();
                    } else {
                        Toast("success", "Boas Vindas de volta!");
                        successAction();
                    }
                }
            } catch (err) {
                Toast("warning", "E-mail ou senha incorreta!");
            } finally {
                setLoadingSiginIn(false);
            }

            return null;
        },
        [auth]
    );

    return { signInUser, loadingSignIn };
};
