/* eslint-disable react/no-unstable-nested-components */

import React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import { useSelector } from "react-redux";

export const PageRoute = ({
    route,
    page,
    redirectPath = "/",
    permission = "Public",
    index = false,
}) => {
    const { user, isAuthenticated } = useSelector((state) => state.login);

    const userPermissions = user.permissions;

    const ValidateRoute = () => {
        if (permission !== "Public") {
            if (isAuthenticated && userPermissions.includes(permission)) {
                return page || <Outlet />;
            }

            return <Navigate to={redirectPath} replace />;
        }

        return page || <Outlet />;
    };

    if (index) {
        <Route index element={<ValidateRoute>{page}</ValidateRoute>} />;
    }

    return (
        <Route path={route} element={<ValidateRoute>{page}</ValidateRoute>} />
    );
};
