import styled from "styled-components";

export const Container = styled.div`
    overflow-x: hidden;

    @media (min-width: 576px) {
        max-width: 540px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 768px) {
        max-width: 720px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 992px) {
        max-width: 960px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 1200px) {
        max-width: 1600px;
        margin: 0 auto;
        text-align: center;
    }
    .menubar {
        background-color: #78bb76;
        height: 92vh;
        position: relative;
    }

    .menu {
        height: 200px;
        background-color: #64b162;
        margin: 0 auto;
        text-align: center;
        padding-top: 8%;
    }
    .servicessd {
        width: 100px;
        height: 100px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 4%;
        border-radius: 100px;
    }

    .picture {
        height: 100%;
        border-radius: 100px;
    }

    .namemenu {
        color: white;
    }

    .linkitensmenu {
        color: white;
        font-size: 18px;
        text-align: left;
        margin-top: 5%;
        border-bottom: 2px solid #64b162;
    }
    .sair {
        position: absolute;
        bottom: 0;
        background-color: #3c6d3a;
        width: 100%;
    }

    .linkitensmenusair {
        color: white;
        font-size: 18px;
        margin-top: 1%;
        padding-left: 3%;
        text-align: left;
    }
`;
