import { deleteDoc, doc } from "firebase/firestore";
import { useCallback, useState } from "react";

import Toast from "../../../components/Toast";

import { firestore } from "../../../services/Firebase";

export default () => {
    const [loadingDeleteService, setLoadingDeleteService] = useState(false);

    const deleteService = useCallback(
        async (serviceId) => {
            setLoadingDeleteService(true);

            try {
                await deleteDoc(doc(firestore, "services", serviceId));

                Toast("success", "Serviço deletado com sucesso!");

                return true;
            } catch (error) {
                Toast(
                    "warning",
                    "Não foi possível excluir o serviço selecionado!"
                );

                return false;
            } finally {
                setLoadingDeleteService(false);
            }
        },
        [firestore]
    );

    return { deleteService, loadingDeleteService };
};
