import React from "react";
import { useSelector } from "react-redux";

import { Container } from "./styles";

const AdminHeader = () => {
    const { title } = useSelector((state) => state.adminHeader);

    return (
        <Container>
            <div className="header">
                <h4 className="titleheader">{title}</h4>
            </div>
        </Container>
    );
};

export default AdminHeader;
