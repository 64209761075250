import styled from "styled-components";

export const Container = styled.div`
    background-color: #3d883b;
    height: 100vh;

    overflow-x: hidden;
    @media (min-width: 576px) {
        max-width: 540px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 768px) {
        max-width: 720px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 992px) {
        max-width: 960px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 1200px) {
        max-width: 1600px;
        margin: 0 auto;
        text-align: center;
    }

    .form--user__icon span {
        font-size: 32px;
        position: absolute !important;
        top: 50% !important;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .container {
        width: 20%;
        margin: 0 auto;

        border-radius: 4px;
        margin-top: 25%;
        z-index: 2;
        color: #fff;
        content: "";
        padding: 0;
        left: 10%;
        transform: translate(-50%, -50%);
        background: rgba(203, 203, 203, 0.56);
        border: 3px solid #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
    }

    @media only screen and (max-width: 600px) {
        .container {
            width: 80%;
            left: 40%;
            margin-top: 80%;
        }
    }

    .form--user__icon {
        border-radius: 50%;
        height: 150px;
        z-index: 9;
        top: -80px;
        text-align: center;
        left: 50%;
        transform: translate(-50%, 0);
        position: absolute;
        background: white;
        width: 150px;
        color: #fff;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    button {
        width: 100%;
        color: #fff !important;
    }

    .colorfotter {
        background-color: #3c6d3a;
    }

    form a {
        color: #fff !important;
        text-decoration: underline !important;
    }

    .itensforms {
        background: rgba(219, 219, 219, 0.5);
        border-radius: 4px;
        border-color: #dbdbdb;
    }

    .spaceform {
        margin-top: 30%;
        margin-bottom: 5%;
    }

    .textremember {
        text-align: left;
    }

    .itens {
        margin-top: 2%;
        margin-bottom: 8%;
    }

    .iconlogin {
        color: rgba(125, 123, 123, 0.86);
        border-radius: 4px;
    }
    .logo {
        width: 70%;
        margin: 0 auto;
        margin-top: 15%;
    }
`;
