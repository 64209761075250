import { collection, getDocs, query, where } from "firebase/firestore";
import { useCallback, useState } from "react";

import Toast from "../../../components/Toast";

import { firestore } from "../../../services/Firebase";

export default () => {
    const [loadingFindAllServices, setLoadingFindAllServices] = useState(false);
    const [dataServices, setDataServices] = useState([]);

    const findAllServices = useCallback(async () => {
        setLoadingFindAllServices(true);

        try {
            const services = [];

            const servicesRef = collection(firestore, "services");

            const dataQuery = query(servicesRef, where("active", "==", true));

            const querySnapshot = await getDocs(dataQuery);

            querySnapshot.forEach((doc) => {
                services.push({
                    id: doc.id,
                    ...doc.data(),
                });
            });

            setDataServices(services);

            return null;
        } catch (error) {
            Toast("warning", "Serviços não foram encontrados!");

            return null;
        } finally {
            setLoadingFindAllServices(false);
        }
    }, [firestore]);

    return { findAllServices, dataServices, loadingFindAllServices };
};
