import { doc, setDoc } from "firebase/firestore";
import { useCallback, useState } from "react";

import Toast from "../../../components/Toast";

import { firestore } from "../../../services/Firebase";

export default () => {
    const [loadingCreateService, setLoadingCreateService] = useState(false);

    const createService = useCallback(
        async (data, serviceId) => {
            setLoadingCreateService(true);

            try {
                await setDoc(doc(firestore, "services", serviceId), {
                    ...data,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                });

                Toast("success", "Serviço cadastrado com sucesso!");

                return true;
            } catch (error) {
                Toast(
                    "warning",
                    "Não foi possível criar o Serviço, tente novamente!"
                );

                return false;
            } finally {
                setLoadingCreateService(false);
            }
        },
        [firestore]
    );

    return { createService, loadingCreateService };
};
