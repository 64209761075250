/* eslint-disable operator-linebreak */
import React, { useState } from "react";
import { v4 } from "uuid";

import Toast from "../../../../components/Toast";

import useCreateUser from "../../../../controller/FirebaseController/Authentication/useCreateUser";
import useSignInUser from "../../../../controller/FirebaseController/Authentication/useSignInUser";
import useCreateService from "../../../../controller/FirebaseController/Services/useCreateService";
import useDeleteService from "../../../../controller/FirebaseController/Services/useDeleteService";
import useFindAllServices from "../../../../controller/FirebaseController/Services/useFindAllServices";
import useFindAllServicesByServiceTypeId from "../../../../controller/FirebaseController/Services/useFindAllServicesByServiceTypeId";
import useUpdateService from "../../../../controller/FirebaseController/Services/useUpdateService";
import useCreateServiceType from "../../../../controller/FirebaseController/ServiceTypes/useCreateServiceType";
import useDeleteServiceType from "../../../../controller/FirebaseController/ServiceTypes/useDeleteServiceType";
import useFindAllServiceTypes from "../../../../controller/FirebaseController/ServiceTypes/useFindAllServiceTypes";
import useUpdateServiceType from "../../../../controller/FirebaseController/ServiceTypes/useUpdateServiceType";
import useUploadImages from "../../../../controller/FirebaseController/Images/useUploadImages";
import useFindUser from "../../../../controller/FirebaseController/User/useFindUser";
import { SearchValueInList } from "../../../../controller/SearchValueInList";

import { Container } from "./styles";

const AdminTest = () => {
    // User
    const { createUser, loadingRegister } = useCreateUser();
    const { signInUser, loadingSignIn } = useSignInUser();
    const { findUserUser, loadingFindUser } = useFindUser();

    // Services
    const { findAllServices, loadingFindAllServices } = useFindAllServices();
    const { deleteService, loadingDeleteService } = useDeleteService();
    const {
        findAllServicesByServiceTypeId,
        loadingFindAllServicesByServiceTypeId,
    } = useFindAllServicesByServiceTypeId();
    const { createService, loadingCreateService } = useCreateService();
    const { updateService, loadingUpdateService } = useUpdateService();

    // Upload Images
    const { uploadImages, loadingUploadImages } = useUploadImages();

    // Service Types
    const { findAllServiceTypes, loadingFindAllServiceTypes } =
        useFindAllServiceTypes();
    const { deleteServiceType, loadingDeleteServiceType } =
        useDeleteServiceType();
    const { createServiceType, loadingCreateServiceType } =
        useCreateServiceType();
    const { updateServiceType, loadingUpdateServiceType } =
        useUpdateServiceType();

    const handleCreateUser = async () => {
        await createUser("italohoho@hotmail.com", "123456789", () => {
            console.log("OK");
        });
    };

    const handleAuthenticationUser = async () => {
        await signInUser("italohoho@hotmail.com", "123456789", () => {
            console.log("OK");
        });
    };

    const handleFindUser = async () => {
        const user = await findUserUser("italohoho@hotmail.com");

        console.log(user);
    };

    const handleFullAuthentication = async () => {
        const user = "italohoho@hotmail.com";

        await signInUser(
            user,
            "123456789",
            async () => {
                const dataUser = await findUserUser(user);

                console.log(dataUser);

                Toast("success", "Boas Vindas!");
            },
            true
        );
    };

    const handleFindAllServices = async () => {
        const services = await findAllServices();

        console.log(services);
    };

    const handleSearch = () => {
        const list = SearchValueInList(
            [
                { name: "Nome aqui", description: "Descrição Aqui" },
                {
                    name: "Outro Nome aqui",
                    description: "outra Descrição Aqui",
                },
                {
                    name: "Xicara",
                    description: "Xuxa",
                },
            ],
            ["name", "description"],
            "x"
        );

        console.log(list);
    };

    const handleDeleteService = async () => {
        const result = await deleteService("jrP86ja014s4Gt7lgoLP");

        console.log(result);
    };

    const handleFindAllServiceTypes = async () => {
        const serviceTypes = await findAllServiceTypes();

        console.log(serviceTypes);
    };

    const [imageUpload, setImageUpload] = useState(null);

    const handleUploadImage = async () => {
        if (imageUpload == null) return;

        const result = await uploadImages(
            [imageUpload],
            "Services/AfterImages/"
        );

        console.log("result: ", result);
    };

    const handleFindAllServiceByServiceTypeId = async () => {
        const services = await findAllServicesByServiceTypeId(
            "PCrsNoZGLftMJc1W4QrJ"
        );

        console.log(services);
    };

    const handleDeleteServiceType = async () => {
        const serviceTypeId = "PCrsNoZGLftMJc1W4QrJ";

        const services = await findAllServicesByServiceTypeId(serviceTypeId);

        console.log(services);

        if (services.length > 0) {
            Toast(
                "warning",
                "Não foi possível excluir o Tipo de Serviço selecionado, o mesmo possui Serviços vinculados!"
            );
        } else {
            const result = await deleteServiceType(serviceTypeId);

            console.log(result);
        }
    };

    const handleCreateService = async () => {
        const result = await createService(
            {
                active: true,
                name: "Serviços de teste",
                description: "Descrição de teste",
                idServiceType: "",
                beforeImagesUrl: [],
                afterImagesUrl: [
                    "Elegancia_69844a80-737b-40d1-9d1e-af8ec6eb3d72.png",
                ],
            },
            v4()
        );

        console.log(result);
    };

    const handleUpdateService = async () => {
        const result = await updateService(
            {
                name: "Atualização Serviços de teste",
                description: "Atualização Descrição de teste",
            },
            "f41c5d4a-d13d-4193-ac03-2916822fdb49"
        );

        console.log(result);
    };

    const handleCreateServiceType = async () => {
        const result = await createServiceType(
            {
                active: true,
                name: "Tipo de Serviço de teste",
                urlImage: "Elegancia_69844a80-737b-40d1-9d1e-af8ec6eb3d72.png",
            },
            v4()
        );

        console.log(result);
    };

    const handleUpdateServiceType = async () => {
        const result = await updateServiceType(
            {
                name: "Atualizando Tipo de Serviço de teste",
            },
            "98f1546f-af6e-4059-9efe-57a1bbddc9b3"
        );

        console.log(result);
    };

    return (
        <Container>
            <h1>Admin</h1>
            <br />
            <h3>User</h3>
            <button type="button" onClick={handleCreateUser}>
                {loadingRegister ? "Carregando" : "Create"}
            </button>
            <button type="button" onClick={handleAuthenticationUser}>
                {loadingSignIn ? "Carregando" : "Login"}
            </button>
            <button type="button" onClick={handleFindUser}>
                {loadingFindUser ? "Carregando" : "Buscar"}
            </button>
            <button type="button" onClick={handleFullAuthentication}>
                {loadingSignIn ? "Carregando" : "Full Login"}
            </button>
            <br />
            <br />
            <h3>Serviços</h3>
            <button type="button" onClick={handleFindAllServices}>
                {loadingFindAllServices ? "Carregando" : "Buscar Serviços"}
            </button>
            <button type="button" onClick={handleSearch}>
                Pesquisar
            </button>
            <button type="button" onClick={handleDeleteService}>
                {loadingDeleteService ? "Carregando" : "Excluir Serviços"}
            </button>
            <div>
                <input
                    type="file"
                    onChange={(event) => {
                        const name = event.target.files[0].name.split(".");

                        setImageUpload({
                            file: event.target.files[0],
                            name: `${name[0]}_${v4()}.${name[1]}`,
                        });
                    }}
                />
                <button type="button" onClick={handleUploadImage}>
                    {loadingUploadImages ? "Carregando" : "Upload File"}
                </button>
            </div>
            <button type="button" onClick={handleFindAllServiceByServiceTypeId}>
                {loadingFindAllServicesByServiceTypeId
                    ? "Carregando"
                    : "Buscar Serviços pelo id do tipo de serviço"}
            </button>
            <button type="button" onClick={handleCreateService}>
                {loadingCreateService ? "Carregando" : "Criar Serviço"}
            </button>
            <button type="button" onClick={handleUpdateService}>
                {loadingUpdateService ? "Carregando" : "Atualizar Serviço"}
            </button>
            <br />
            <br />
            <h3>Tipos de Serviços</h3>
            <button type="button" onClick={handleFindAllServiceTypes}>
                {loadingFindAllServiceTypes
                    ? "Carregando"
                    : "Buscar Tipos de Serviços"}
            </button>
            <button type="button" onClick={handleDeleteServiceType}>
                {loadingDeleteServiceType
                    ? "Carregando"
                    : "Excluir Tipo de Serviço"}
            </button>
            <button type="button" onClick={handleCreateServiceType}>
                {loadingCreateServiceType
                    ? "Carregando"
                    : "Criar Tipo de Serviço"}
            </button>
            <button type="button" onClick={handleUpdateServiceType}>
                {loadingUpdateServiceType
                    ? "Carregando"
                    : "Atualizar Tipo de Serviço"}
            </button>
        </Container>
    );
};

export default AdminTest;
