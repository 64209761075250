/* eslint-disable no-unused-vars */
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useCallback, useState } from "react";

import { auth } from "../../../services/Firebase";

import Toast from "../../../components/Toast";

export default () => {
    const [loadingRegister, setLoadingRegister] = useState(false);

    const createUser = useCallback(
        async (email, password, successAction = () => {}) => {
            setLoadingRegister(true);

            try {
                const user = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );

                if (user) {
                    Toast(
                        "success",
                        "E-mail cadastrado com sucesso! Boas Vindas!"
                    );
                    successAction();
                }
            } catch (error) {
                Toast("warning", "E-mail já cadastrado!");
            } finally {
                setLoadingRegister(false);
            }

            return null;
        },
        [auth]
    );

    return { createUser, loadingRegister };
};
