import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useSelector, useDispatch } from "react-redux";

import Cliente from "../../../../assets/cliente.png";
import { RouteURLs } from "../../../../Routes/RouteData";

import { Container } from "./styles";
import { HeaderActions } from "../AdminHeader/actions";
import { LoginActions } from "../../../Login/actions";

const MenuBar = ({ children }) => {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.login);

    const handleChangeHeaderTitle = (title) => {
        dispatch({ type: HeaderActions.CHANGE_HEADER_ACTION, title });
    };

    const handleSigOut = () => {
        dispatch({ type: LoginActions.USER_SIGOUT_ACTION });
        dispatch({
            type: HeaderActions.CHANGE_HEADER_ACTION,
            title: "Administrador SDGestão",
        });
    };

    return (
        <Container>
            <div className="row ">
                <div className="col-sm-3">
                    <div className="menubar">
                        <div className="menu">
                            <div className="servicessd">
                                <img
                                    className="img-fluid picture"
                                    src={
                                        user.urlImage === ""
                                            ? Cliente
                                            : user.urlImage
                                    }
                                    alt="logo"
                                />
                            </div>
                            <h5 className="namemenu">{user.name}</h5>
                        </div>
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <Link
                                    className="nav-link linkitensmenu"
                                    onClick={() =>
                                        handleChangeHeaderTitle(
                                            "Administrador SDGestão"
                                        )
                                    }
                                    to={RouteURLs.adminHome}
                                >
                                    Início
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link linkitensmenu"
                                    onClick={() =>
                                        handleChangeHeaderTitle("Serviços")
                                    }
                                    to={RouteURLs.adminServices}
                                >
                                    Serviços
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link linkitensmenu"
                                    onClick={() =>
                                        handleChangeHeaderTitle(
                                            "Tipos de Serviços"
                                        )
                                    }
                                    to={RouteURLs.adminServicesTypes}
                                >
                                    Tipos de serviços
                                </Link>
                            </li>
                            <li className="nav-item sair">
                                <Link
                                    className="nav-link linkitensmenusair"
                                    onClick={handleSigOut}
                                    to={RouteURLs.home}
                                >
                                    Sair
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-9">{children}</div>
            </div>
        </Container>
    );
};

export default MenuBar;
