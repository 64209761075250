export const RouteURLs = {
    home: "/",
    notFound: "*",
    login: "login",
    rastreamento: "/rastreamento",
    enlonamento: "/enlonamento",
    andaime: "/andaime",
    locacao: "/locacao",
    arcondicionado: "/arcondicionado",
    octoplus: "/octoplus",
    caditemservico: "/cadastroitem",
    itempreenchido: "/itempreenchido",
    listcadservice: "/listaservicos",
    service: "/servico",

    adminHome: "/areaRestrita",
    adminNotFound: "/areaRestrita/*",
    adminTest: "/adminTest",
    adminServices: "/areaRestrita/servicos",
    adminService: "/areaRestrita/servico",
    adminServicesTypes: "/areaRestrita/tiposservicos",
    adminServiceType: "/areaRestrita/tiposervico",
};

export const RoutePermissions = {
    admin: "Admin",
};
