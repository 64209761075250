/* eslint-disable operator-linebreak */

import { collection, getDocs, query, where } from "firebase/firestore";
import { useCallback, useState } from "react";

import Toast from "../../../components/Toast";

import { firestore } from "../../../services/Firebase";

export default () => {
    const [
        loadingFindAllServicesByServiceTypeId,
        setLoadingFindAllServicesByServiceTypeId,
    ] = useState(false);
    const [dataAllServicesByServiceTypeId, setDataAllServicesByServiceTypeId] =
        useState([]);

    const findAllServicesByServiceTypeId = useCallback(
        async (serviceTypeId) => {
            setLoadingFindAllServicesByServiceTypeId(true);

            try {
                const services = [];

                const servicesRef = collection(firestore, "services");

                const dataQuery = query(
                    servicesRef,
                    where("idServiceType", "==", serviceTypeId)
                );

                const querySnapshot = await getDocs(dataQuery);

                querySnapshot.forEach((doc) => {
                    services.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });

                setDataAllServicesByServiceTypeId(services);

                return null;
            } catch (error) {
                Toast("warning", "Serviços não foram encontrados!");

                return null;
            } finally {
                setLoadingFindAllServicesByServiceTypeId(false);
            }
        },
        [firestore]
    );

    return {
        findAllServicesByServiceTypeId,
        dataAllServicesByServiceTypeId,
        loadingFindAllServicesByServiceTypeId,
    };
};
