import { Route } from "react-router-dom";
import loadable from "@loadable/component";

import { RouteURLs } from "./RouteData";
import { PageRoute } from "./PageRoute";

const Home = loadable(() => import("../Pages/Home"));
const NotFound = loadable(() => import("../Pages/NotFound"));
const Rastreamento = loadable(() => import("../Pages/Rastreamento"));
const Enlonamento = loadable(() => import("../Pages/Enlonamento"));
const Andaime = loadable(() => import("../Pages/Andaime"));
const Locacao = loadable(() => import("../Pages/Locacao"));
const Arcondiconado = loadable(() => import("../Pages/Arcondicionado"));
const Octoplus = loadable(() => import("../Pages/Octoplus"));
const Login = loadable(() => import("../Pages/Login"));

export const PublicRoutes = () => (
    <>
        {PageRoute({
            route: RouteURLs.home,
            page: <Home />,
            index: true,
        })}
        {PageRoute({
            route: RouteURLs.rastreamento,
            page: <Rastreamento />,
        })}
        {PageRoute({
            route: RouteURLs.enlonamento,
            page: <Enlonamento />,
        })}
        {PageRoute({
            route: RouteURLs.andaime,
            page: <Andaime />,
        })}
        {PageRoute({
            route: RouteURLs.locacao,
            page: <Locacao />,
        })}
        {PageRoute({
            route: RouteURLs.arcondicionado,
            page: <Arcondiconado />,
        })}
        {PageRoute({
            route: RouteURLs.octoplus,
            page: <Octoplus />,
        })}
        {PageRoute({
            route: RouteURLs.login,
            page: <Login />,
        })}

        <Route path={RouteURLs.notFound} element={<NotFound />} />
    </>
);
