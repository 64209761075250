import { deleteDoc, doc } from "firebase/firestore";
import { useCallback, useState } from "react";

import Toast from "../../../components/Toast";

import { firestore } from "../../../services/Firebase";

export default () => {
    // eslint-disable-next-line operator-linebreak
    const [loadingDeleteServiceType, setLoadingDeleteServiceType] =
        useState(false);

    const deleteServiceType = useCallback(
        async (serviceTypeId) => {
            setLoadingDeleteServiceType(true);

            try {
                await deleteDoc(doc(firestore, "servicesType", serviceTypeId));

                Toast("success", "Tipo de serviço deletado com sucesso!");

                return true;
            } catch (error) {
                Toast(
                    "warning",
                    "Não foi possível excluir o tipo de serviço selecionado!"
                );

                return false;
            } finally {
                setLoadingDeleteServiceType(false);
            }
        },
        [firestore]
    );

    return { deleteServiceType, loadingDeleteServiceType };
};
