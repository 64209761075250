import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import { RouteURLs } from "./RouteData";

import Header from "../components/Header";
import { PublicRoutes } from "./Public.routes";
import { AdminRoutes } from "./Admin.routes";
import AdminHeader from "../Pages/Admin/Components/AdminHeader";
import MenuBar from "../Pages/Admin/Components/MenuBar";
import Login from "../Pages/Login";
import Principal from "../Pages/Admin/Components/Principal";

const PublicBaseRoute = () => (
    <>
        <Header />
        <Outlet />
    </>
);

const AdminBaseRoute = () => (
    <Principal>
        <AdminHeader />
        <MenuBar>
            <Outlet />
        </MenuBar>
    </Principal>
);

const RoutesApp = () => (
    <BrowserRouter>
        <Routes>
            <Route path={RouteURLs.login} element={<Login />} />

            {/* Public Routs */}
            <Route path={RouteURLs.home} element={<PublicBaseRoute />}>
                {PublicRoutes()}
            </Route>

            {/* Admin Routs */}
            <Route path={RouteURLs.admin} element={<AdminBaseRoute />}>
                {AdminRoutes()}
            </Route>
        </Routes>
    </BrowserRouter>
);

export default RoutesApp;
