/* eslint-disable import/no-mutable-exports */

import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

import { firebaseConfig } from "./config";

let analytics = null;
let firestore = null;
let auth = null;
let storage = null;

if (!getApps.length) {
    const firebaseApp = initializeApp(firebaseConfig);

    firestore = initializeFirestore(firebaseApp, {
        experimentalForceLongPolling: true,
    });

    analytics = getAnalytics(firebaseApp);

    auth = getAuth(firebaseApp);

    storage = getStorage();
}

export { analytics, firestore, auth, storage };
