import { doc, setDoc } from "firebase/firestore";
import { useCallback, useState } from "react";

import Toast from "../../../components/Toast";

import { firestore } from "../../../services/Firebase";

export default () => {
    // eslint-disable-next-line operator-linebreak
    const [loadingCreateServiceType, setLoadingCreateServiceType] =
        useState(false);

    const createServiceType = useCallback(
        async (data, serviceTypeId) => {
            setLoadingCreateServiceType(true);

            try {
                await setDoc(doc(firestore, "servicesType", serviceTypeId), {
                    ...data,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                });

                Toast("success", "Tipo de Serviço cadastrado com sucesso!");

                return true;
            } catch (error) {
                Toast(
                    "warning",
                    "Não foi possível criar o Tipo de Serviço, tente novamente!"
                );

                return false;
            } finally {
                setLoadingCreateServiceType(false);
            }
        },
        [firestore]
    );

    return { createServiceType, loadingCreateServiceType };
};
