import { doc, setDoc } from "firebase/firestore";
import { useCallback, useState } from "react";

import Toast from "../../../components/Toast";

import { firestore } from "../../../services/Firebase";

export default () => {
    const [loadingUpdateService, setLoadingUpdateService] = useState(false);

    const updateService = useCallback(
        async (data, serviceId) => {
            setLoadingUpdateService(true);

            try {
                await setDoc(
                    doc(firestore, "services", serviceId),
                    {
                        ...data,
                        updatedAt: new Date(),
                    },
                    { merge: true }
                );

                Toast("success", "Serviço atualizado com sucesso!");

                return true;
            } catch (error) {
                Toast(
                    "warning",
                    "Não foi possível atualizar o Serviço, tente novamente!"
                );

                console.log(error);

                return false;
            } finally {
                setLoadingUpdateService(false);
            }
        },
        [firestore]
    );

    return { updateService, loadingUpdateService };
};
