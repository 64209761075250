import { doc, setDoc } from "firebase/firestore";
import { useCallback, useState } from "react";

import Toast from "../../../components/Toast";

import { firestore } from "../../../services/Firebase";

export default () => {
    // eslint-disable-next-line operator-linebreak
    const [loadingUpdateServiceType, setLoadingUpdateServiceType] =
        useState(false);

    const updateServiceType = useCallback(
        async (data, serviceTypeId) => {
            setLoadingUpdateServiceType(true);

            try {
                await setDoc(
                    doc(firestore, "servicesType", serviceTypeId),
                    {
                        ...data,
                        updatedAt: new Date(),
                    },
                    { merge: true }
                );

                Toast("success", "Tipo de Serviço atualizado com sucesso!");

                return true;
            } catch (error) {
                Toast(
                    "warning",
                    "Não foi possível atualizar o Tipo de Serviço, tente novamente!"
                );

                return false;
            } finally {
                setLoadingUpdateServiceType(false);
            }
        },
        [firestore]
    );

    return { updateServiceType, loadingUpdateServiceType };
};
