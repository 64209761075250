import styled from "styled-components";

export const Container = styled.div`


    @media (min-width: 576px) {
        max-width: 540px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 768px) {
        max-width: 720px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 992px) {
        max-width: 960px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 1200px) {
        max-width: 1600px;
        margin: 0 auto;
        text-align: center;
    }
    .cont {
        overflow-x: hidden;
        overflow-y: hidden;

        height: 100vh;
    }

    @media only screen and (max-width: 600px) {
        .cont {
      
    
            height: 100%;
        }
{

}
`;
