/* eslint-disable function-paren-newline */
/* eslint-disable operator-linebreak */

export const SearchValueInList = (list, searchProps, value) =>
    list.filter((item) =>
        item[searchProps[0]]?.toLowerCase()?.includes(value.toLowerCase()) ||
        item[searchProps[1]]?.toLowerCase()?.includes(value.toLowerCase())
            ? item
            : null
    );
