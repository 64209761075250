import React from "react";

import { Container } from "./styles";

const Principal = ({ children }) => (
    <Container>
        <div className="cont">{children}</div>
    </Container>
);

export default Principal;
