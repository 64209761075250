/* eslint-disable operator-linebreak */
import React, { useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { HiLockClosed } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Toast from "../../components/Toast";

import useSignInUser from "../../controller/FirebaseController/Authentication/useSignInUser";
import useFindUser from "../../controller/FirebaseController/User/useFindUser";
import { LoginActions } from "./actions";

import Logo from "../../assets/logo.png";

import { Container } from "./styles";
import { RouteURLs } from "../../Routes/RouteData";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [user, setUser] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);

    const { signInUser } = useSignInUser();
    const { findUserUser } = useFindUser();

    const handleSignIn = async () => {
        if (user === "" || password === "") {
            Toast("warning", "Todos os campos devem ser preenchidos!");
        } else {
            setLoading(true);

            await signInUser(
                user,
                password,
                async () => {
                    const { email, name, permissions, urlImage } =
                        await findUserUser(user);

                    dispatch({
                        type: LoginActions.USER_SIGIN_ACTION,
                        user: {
                            email,
                            name,
                            permissions,
                            urlImage,
                        },
                    });

                    Toast("success", "Boas Vindas!");

                    navigate(RouteURLs.adminHome);
                },
                true
            );

            setLoading(false);
        }
    };

    return (
        <Container>
            <div className="card container">
                <div className="card-body">
                    <div className="form--user__icon">
                        <div className="icon--img">
                            <img
                                className="img-fluid logo"
                                alt="logo"
                                src={Logo}
                            />
                        </div>
                    </div>
                    <div className="spaceform">
                        <form action="/action_page.php">
                            <div className="input-group input-group-lg itens">
                                <span className="input-group-text itensforms">
                                    <FaUserAlt className="iconlogin" />
                                </span>
                                <input
                                    type="text"
                                    className="form-control itensforms"
                                    placeholder="Email"
                                    value={user}
                                    onChange={(event) =>
                                        setUser(event.target.value)
                                    }
                                />
                            </div>
                            <div className="input-group input-group-lg  itens">
                                <span className="input-group-text itensforms">
                                    <HiLockClosed className="iconlogin" />
                                </span>
                                <input
                                    type="password"
                                    className="form-control itensforms"
                                    placeholder="Senha"
                                    value={password}
                                    onChange={(event) =>
                                        setPassword(event.target.value)
                                    }
                                />
                            </div>

                            <div className="form-check textremember">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="check1"
                                    name="option1"
                                    value="something"
                                />
                                <label
                                    htmlFor="Lembre-me"
                                    className="form-check-label textremember"
                                >
                                    Lembre-me
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card-footer colorfotter">
                    <button
                        type="button"
                        className="btn btn-default"
                        onClick={handleSignIn}
                    >
                        {!loading ? (
                            "Entrar"
                        ) : (
                            <div className="spinner-border" role="status">
                                <span className="sr-only" />
                            </div>
                        )}
                    </button>
                </div>
            </div>
        </Container>
    );
};

export default Login;
