import styled from "styled-components";

export const Container = styled.div`
    @media (min-width: 576px) {
        max-width: 540px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 768px) {
        max-width: 720px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 992px) {
        max-width: 960px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 1200px) {
        max-width: 1600px;
        margin: 0 auto;
        text-align: center;
    }
    .header {
        background-color: #5da55b;
        height: 60px;
        text-align: center;
        margin: 0 auto;
    }
    .titleheader {
        color: white;
        padding-top: 1%;
    }

    @media only screen and (max-width: 600px) {
        .titleheader {
            padding-top: 5%;
        }
    }
`;
