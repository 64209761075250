/* eslint-disable no-await-in-loop, operator-linebreak */

import { collection, getDocs, query, where } from "firebase/firestore";
// import { getDownloadURL, ref } from "firebase/storage";
import { useCallback, useState } from "react";

import Toast from "../../../components/Toast";

import { firestore, storage } from "../../../services/Firebase";

export default () => {
    const [loadingFindAllServiceTypes, setLoadingFindAllServiceTypes] =
        useState(false);
    const [dataServiceTypes, setDataServiceTypes] = useState([]);

    const findAllServiceTypes = useCallback(async () => {
        setLoadingFindAllServiceTypes(true);

        try {
            const serviceTypes = [];
            // const newServiceTypes = [];

            const serviceTypesRef = collection(firestore, "servicesType");

            const dataQuery = query(
                serviceTypesRef,
                where("active", "==", true)
            );

            const querySnapshot = await getDocs(dataQuery);

            querySnapshot.forEach((doc) => {
                serviceTypes.push({
                    id: doc.id,
                    ...doc.data(),
                });
            });

            // for (let index = 0; index < serviceTypes.length; index += 1) {
            //     const dataServiceType = serviceTypes[index];

            //     if (dataServiceType.urlImage !== "") {
            //         let url = "";

            //         const pathReference = ref(
            //             storage,
            //             `ServicesType/${dataServiceType.urlImage}`
            //         );

            //         await getDownloadURL(pathReference)
            //             .then((dataUrl) => {
            //                 url = dataUrl;
            //             })
            //             .catch((error) => {
            //                 console.log(error);
            //                 url = "";
            //             });

            //         newServiceTypes.push({
            //             ...dataServiceType,
            //             urlImage: url,
            //         });
            //     } else {
            //         newServiceTypes.push(dataServiceType);
            //     }
            // }

            setDataServiceTypes(serviceTypes);

            return null;
        } catch (error) {
            Toast("warning", "Tipos de Serviços não foram encontrados!");

            return null;
        } finally {
            setLoadingFindAllServiceTypes(false);
        }
    }, [firestore, storage]);

    return {
        findAllServiceTypes,
        dataServiceTypes,
        loadingFindAllServiceTypes,
    };
};
