/* eslint-disable default-param-last */

import { HeaderActions } from "./actions";

const INITIAL_STATE = {
    title: "Administrador SDGestão",
};

export const AdminHeaderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case HeaderActions.CHANGE_HEADER_ACTION:
            return { ...state, title: action.title };

        default:
            return { ...state };
    }
};
