/* eslint-disable function-paren-newline */
import { Route } from "react-router-dom";
import loadable from "@loadable/component";

import { RoutePermissions, RouteURLs } from "./RouteData";
import { PageRoute } from "./PageRoute";
import AdminTest from "../Pages/Admin/Pages/AdminTest";

const AdminHome = loadable(() => import("../Pages/Admin/Pages/Home"));
const CadItemServico = loadable(() =>
    import("../Pages/Admin/Pages/CadItemServico")
);
const Itempreenchido = loadable(() =>
    import("../Pages/Admin/Pages/Itempreenchido")
);
const ListCadService = loadable(() =>
    import("../Pages/Admin/Pages/ListCadService")
);

const Service = loadable(() => import("../Pages/Admin/Pages/Service"));
const AdminNotFound = loadable(() => import("../Pages/Admin/Pages/NotFound"));

export const AdminRoutes = () => (
    <>
        {PageRoute({
            route: RouteURLs.adminHome,
            page: <AdminHome />,
            permission: RoutePermissions.admin,
        })}

        {PageRoute({
            route: RouteURLs.adminTest,
            page: <AdminTest />,
            permission: RoutePermissions.admin,
        })}

        {PageRoute({
            route: RouteURLs.adminServiceType,
            page: <CadItemServico />,
            permission: RoutePermissions.admin,
        })}

        {PageRoute({
            route: RouteURLs.adminServicesTypes,
            page: <Itempreenchido />,
            permission: RoutePermissions.admin,
        })}

        {PageRoute({
            route: RouteURLs.adminServices,
            page: <ListCadService />,
            permission: RoutePermissions.admin,
        })}

        {PageRoute({
            route: RouteURLs.adminService,
            page: <Service />,
            permission: RoutePermissions.admin,
        })}

        <Route
            path={`${RouteURLs.admin}/${RouteURLs.adminNotFound}`}
            element={<AdminNotFound />}
        />
    </>
);
