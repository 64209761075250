import { collection, getDocs, query, where } from "firebase/firestore";
import { useCallback, useState } from "react";

import { firestore } from "../../../services/Firebase";

import Toast from "../../../components/Toast";

export default () => {
    const [loadingFindUser, setLoadingFindUser] = useState(false);

    const findUserUser = useCallback(
        async (email) => {
            setLoadingFindUser(true);

            try {
                const user = [];

                const userRef = collection(firestore, "users");

                const dataQuery = query(userRef, where("email", "==", email));

                const querySnapshot = await getDocs(dataQuery);

                querySnapshot.forEach((doc) => {
                    user.push({
                        id: doc.id,
                        ...doc.data(),
                        createdAt: new Date(
                            doc.data().createdAt.seconds * 1000
                        ),
                        updatedAt: new Date(
                            doc.data().updatedAt.seconds * 1000
                        ),
                    });
                });

                if (user.length > 0) {
                    return user[0];
                }

                Toast("warning", "usuário não foi encontrado!");

                return user;
            } catch (err) {
                Toast("warning", "usuário não foi encontrado!");

                return [];
            } finally {
                setLoadingFindUser(false);
            }
        },
        [firestore]
    );

    return { findUserUser, loadingFindUser };
};
