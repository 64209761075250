/* eslint-disable default-param-last */

import { LoginActions } from "./actions";

const INITIAL_STATE = {
    isAuthenticated: false,
    user: {},
};

export const LoginReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LoginActions.USER_SIGIN_ACTION:
            return { ...state, isAuthenticated: true, user: action.user };

        case LoginActions.USER_SIGOUT_ACTION:
            return { ...state, isAuthenticated: false, user: {} };

        default:
            return { ...state };
    }
};
