/* eslint-disable no-await-in-loop */
/* eslint-disable no-loop-func */

import { ref, uploadBytes } from "firebase/storage";
import { useCallback, useState } from "react";
import { v4 } from "uuid";

import Toast from "../../../components/Toast";

import { storage } from "../../../services/Firebase";

export default () => {
    const [loadingUploadImages, setLoadingUploadImages] = useState(false);

    const uploadImages = useCallback(
        async (images = [], location = "", minSize = 4, url = "") => {
            setLoadingUploadImages(true);

            const result = [];

            try {
                if (images.length > 0) {
                    if (images.length > minSize) {
                        Toast(
                            "warning",
                            `Máximo de imagens permitidas são ${minSize}!`
                        );

                        result.push({
                            status: false,
                            imageName: "",
                            imageUrl: "",
                        });

                        return result;
                    }

                    for (let index = 0; index < images.length; index += 1) {
                        const imageName = `${v4()}_${images[index].name}`;

                        const imageRef = ref(
                            storage,
                            `${location}${imageName}`
                        );

                        await uploadBytes(imageRef, images[index].file)
                            .then(() => {
                                const imageUrl = url.replace(
                                    ":imageName:",
                                    imageName
                                );

                                result.push({
                                    status: true,
                                    imageName,
                                    imageUrl,
                                });
                            })
                            .catch((error) => {
                                Toast(
                                    "error",
                                    "Algo de errado aconteceu durante o envio das imagens, favor tente novamente!"
                                );

                                result.push({
                                    status: false,
                                    imageName: "",
                                    imageUrl: "",
                                });

                                console.log(error);
                            });
                    }

                    return result;
                }

                Toast(
                    "warning",
                    "Deverá haver ao menos uma imagem para realizar o upload!"
                );

                result.push({
                    status: false,
                    imageName: "",
                    imageUrl: "",
                });

                return result;
            } catch (error) {
                Toast(
                    "error",
                    "Algo de errado aconteceu durante o envio das imagens, favor tente novamente!"
                );

                result.push({
                    status: false,
                    imageName: "",
                    imageUrl: "",
                });

                return result;
            } finally {
                setLoadingUploadImages(false);
            }
        },
        [storage]
    );

    return { uploadImages, loadingUploadImages };
};
